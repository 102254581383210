import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getSelectedBusinessData, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import ActivityActions from "actions/ActivityActions";
import AddQuickBooksDesktopStep from "./lib/AddQuickBooksDesktopStep";
import BusinessDescriptionStep from "./lib/BusinessDescriptionStep";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import InviteUserStep from "./lib/InviteUserStep";
import QuickBooksCompleteStep from "./lib/QuickBooksCompleteStep";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RpaSettingUpStep from "./lib/RpaSettingUpStep";
import RpaSettingsStep from "./lib/RpaSettingsStep";
import SelectFrequencyStep from "./lib/SelectFrequencyStep";
import SelectServiceStep from "./lib/SelectServiceStep";
import SyncEntitiesStep from "./lib/SyncEntitiesStep";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import WhoAskedStep from "./lib/WhoAskedStep";
import classNames from "classnames";

const STEP_FIRST = 0;

const STEP_SECOND = 1;

const STEP_THIRD = 2;

const STEP_FOURTH = 3;

const STEP_FIFTH = 4;

const STEP_SIXTH = 5;

const STEP_SEVENTH = 6;

const STEP_EIGHTH = 7;

const Onboarding = (props) => {
  const { service: serviceToConnect, rpaMode } = props;

  const dispatch = useDispatch();

  const [newBusinessId, setNewBusinessId] = useState(null);

  const { error: envVarsError } = useMemo(() => {
    return Utils.parseQueryString(window.location.search);
  }, []);

  const { uiTexts, messages } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const allUserBusinessesData = useSelector(getUserBusinessesData);

  const {
    id: selectedBusinessId,
    extraData = {},
    settings: {
      rpaFrequency,
      rpaStartFrom,
      rpaTransactionTypes,
      rpaAutoAskClient,
      businessDescription
    } = {}
  } = useSelector(getSelectedBusinessData);

  const {
    integrationService,
    lastParsingRpaAt,
    lastReconciliationRpaAt,
    lastEntitiesAutoSetAt,
    uncategorizedRpaEnabled,
    reconciliationRpaEnabled,
    integrationServiceConnected
  } = extraData;

  const quickBooksService = (serviceToConnect || integrationService) === IntegrationServices.QUICK_BOOKS.value;

  const quickBooksDesktopService = serviceToConnect === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

  const currentStep = useMemo(() => {
    if (envVarsError || (!serviceToConnect && integrationService && !integrationServiceConnected)) return STEP_FIRST;
    if (!selectedBusinessId || (!rpaMode && (!newBusinessId || !lastEntitiesAutoSetAt))) return STEP_SECOND;
    if (businessDescription === undefined) return STEP_THIRD;
    if (rpaFrequency === undefined) return STEP_FOURTH;
    if (rpaStartFrom === undefined || rpaTransactionTypes === undefined) return STEP_FIFTH;
    if (rpaAutoAskClient === undefined) return STEP_SIXTH;
    if (!uncategorizedRpaEnabled && !reconciliationRpaEnabled) return STEP_SEVENTH;

    return STEP_EIGHTH;
  }, [
    serviceToConnect,
    integrationService,
    integrationServiceConnected,
    uncategorizedRpaEnabled,
    reconciliationRpaEnabled,
    rpaAutoAskClient,
    rpaStartFrom,
    rpaTransactionTypes,
    rpaFrequency,
    businessDescription,
    selectedBusinessId,
    rpaMode,
    newBusinessId,
    lastEntitiesAutoSetAt,
    envVarsError
  ]);

  const stepsComponents = useMemo(() => {
    return [
      SelectServiceStep,
      quickBooksDesktopService ? AddQuickBooksDesktopStep : SyncEntitiesStep,
      ...(
        rpaMode
          ? [
            BusinessDescriptionStep,
            SelectFrequencyStep,
            RpaSettingsStep,
            WhoAskedStep,
            InviteUserStep,
            RpaSettingUpStep
          ]
          : [quickBooksService && currentStep > STEP_SECOND && QuickBooksCompleteStep])
    ].filter(Boolean);
  }, [currentStep, quickBooksDesktopService, quickBooksService, rpaMode]);

  const [onboardingTitle] = rpaMode ? messages.onboardingRpaWorkflow : messages.onboardingRegularWorkflow;

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const rpaDidRun = !!(lastParsingRpaAt || lastReconciliationRpaAt) && (rpaAutoAskClient !== undefined);

  const handleBackButtonClick = useCallback(() => {
    window.location.href = UiRoutes.MAIN;
  }, []);

  const fetchData = useCallback(() => {
    dispatch(BusinessesActions.fetchBusiness(selectedBusinessId, true, true));
    dispatch(ActivityActions.fetchActivityList({ backgroundUpdate: true }));
  }, [dispatch, selectedBusinessId]);

  useEffect(() => {
    if (selectedBusinessId) {
      fetchData();

      return Utils.setInterval(fetchData, Constants.DATA_LIST_UPDATE_INTERVAL);
    }

    return () => {};
  }, [selectedBusinessId, fetchData, dispatch]);

  return (
    <div className={classNames(Css.onboarding, rpaDidRun && currentStep === STEP_FOURTH && Css.reconnect)}>
      <div className={Css.container}>
        <div className={Css.card}>
          <div className={Css.title}>
            <div>{onboardingTitle}</div>
            {!businessUser && !!allUserBusinessesData.length && (
              <Button light large outline className={Css.closeButton} onClick={handleBackButtonClick}>
                <Icons.ArrowLeft />
                <span>{uiTexts.back}</span>
              </Button>
            )}
          </div>
          {stepsComponents.map((StepComponent, index) => {
            return (
              <StepComponent
                key={String(`${index}`)}
                index={index}
                rpaMode={rpaMode}
                currentStep={currentStep}
                integrationService={integrationService}
                serviceToConnect={serviceToConnect}
                newBusinessId={newBusinessId}
                setNewBusinessId={setNewBusinessId} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Onboarding);
